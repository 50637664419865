<template>
  <div ref="compRef" class="tw-web-datepicker mb-16">
    <div
      v-if="label || $slots.label"
      :style="labelStyle"
      class="mb-8 flex items-center gap-8 text-14 font-medium text-neutral-900"
    >
      {{ label }}
      <slot name="label" />
    </div>
    <VueDatepicker
      v-bind="$attrs"
      ref="datepicker"
      v-model="dateValue"
      :locale="datepickerLocale"
      position="left"
      :enable-time-picker="enableTimePickerValue"
      :hide-input-icon="hideInputIcon"
      :time-picker="timePicker"
      :range="range"
      :format="(datePickerFormat as DatePickerProp['format'])"
      :preset-ranges="customRanges"
      multi-calendars-solo
      no-today
      :offset="8"
      :month-change-on-scroll="false"
      :menu-class-name="menuClassName"
      :input-class-name="inputClassName"
      calendar-class-name="tw-web-datepicker__calendar"
      calendar-cell-class-name="tw-web-datepicker__calendar-cell"
      @internal-model-change="handleInternalModelChange"
      @closed="onClosed"
    >
      <template #input-icon>
        <svg
          version="1.1"
          role="presentation"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="20"
          height="20"
        >
          <!-- timePicker ? 'Clock' : 'Calendar2' -->
          <path
            v-if="timePicker"
            d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.35 15.57C16.21 15.81 15.96 15.94 15.7 15.94C15.57 15.94 15.44 15.91 15.32 15.83L12.22 13.98C11.45 13.52 10.88 12.51 10.88 11.62V7.52C10.88 7.11 11.22 6.77 11.63 6.77C12.04 6.77 12.38 7.11 12.38 7.52V11.62C12.38 11.98 12.68 12.51 12.99 12.69L16.09 14.54C16.45 14.75 16.57 15.21 16.35 15.57Z"
            fill="currentColor"
          />
          <g v-else>
            <path
              fill="currentColor"
              d="M16.75 3.56V2C16.75 1.59 16.41 1.25 16 1.25C15.59 1.25 15.25 1.59 15.25 2V3.5H8.75V2C8.75 1.59 8.41 1.25 8 1.25C7.59 1.25 7.25 1.59 7.25 2V3.56C4.55 3.81 3.24 5.42 3.04 7.81C3.02 8.1 3.26 8.34 3.54 8.34H20.46C20.75 8.34 20.99 8.09 20.96 7.81C20.76 5.42 19.45 3.81 16.75 3.56Z"
            />
            <path
              fill="currentColor"
              d="M20 9.84H4C3.45 9.84 3 10.29 3 10.84V17C3 20 4.5 22 8 22H16C19.5 22 21 20 21 17V10.84C21 10.29 20.55 9.84 20 9.84ZM9.21 18.21C9.11 18.3 9 18.37 8.88 18.42C8.76 18.47 8.63 18.5 8.5 18.5C8.37 18.5 8.24 18.47 8.12 18.42C8 18.37 7.89 18.3 7.79 18.21C7.61 18.02 7.5 17.76 7.5 17.5C7.5 17.24 7.61 16.98 7.79 16.79C7.89 16.7 8 16.63 8.12 16.58C8.36 16.48 8.64 16.48 8.88 16.58C9 16.63 9.11 16.7 9.21 16.79C9.39 16.98 9.5 17.24 9.5 17.5C9.5 17.76 9.39 18.02 9.21 18.21ZM9.42 14.38C9.37 14.5 9.3 14.61 9.21 14.71C9.11 14.8 9 14.87 8.88 14.92C8.76 14.97 8.63 15 8.5 15C8.37 15 8.24 14.97 8.12 14.92C8 14.87 7.89 14.8 7.79 14.71C7.7 14.61 7.63 14.5 7.58 14.38C7.53 14.26 7.5 14.13 7.5 14C7.5 13.87 7.53 13.74 7.58 13.62C7.63 13.5 7.7 13.39 7.79 13.29C7.89 13.2 8 13.13 8.12 13.08C8.36 12.98 8.64 12.98 8.88 13.08C9 13.13 9.11 13.2 9.21 13.29C9.3 13.39 9.37 13.5 9.42 13.62C9.47 13.74 9.5 13.87 9.5 14C9.5 14.13 9.47 14.26 9.42 14.38ZM12.71 14.71C12.61 14.8 12.5 14.87 12.38 14.92C12.26 14.97 12.13 15 12 15C11.87 15 11.74 14.97 11.62 14.92C11.5 14.87 11.39 14.8 11.29 14.71C11.11 14.52 11 14.26 11 14C11 13.74 11.11 13.48 11.29 13.29C11.39 13.2 11.5 13.13 11.62 13.08C11.86 12.97 12.14 12.97 12.38 13.08C12.5 13.13 12.61 13.2 12.71 13.29C12.89 13.48 13 13.74 13 14C13 14.26 12.89 14.52 12.71 14.71Z"
            />
          </g>
        </svg>
      </template>
      <template #calendar-icon>
        <svg
          version="1.1"
          role="presentation"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="20"
          height="20"
          class="text-neutral-500"
        >
          <g>
            <path
              fill="currentColor"
              d="M16.75 3.56V2C16.75 1.59 16.41 1.25 16 1.25C15.59 1.25 15.25 1.59 15.25 2V3.5H8.75V2C8.75 1.59 8.41 1.25 8 1.25C7.59 1.25 7.25 1.59 7.25 2V3.56C4.55 3.81 3.24 5.42 3.04 7.81C3.02 8.1 3.26 8.34 3.54 8.34H20.46C20.75 8.34 20.99 8.09 20.96 7.81C20.76 5.42 19.45 3.81 16.75 3.56Z"
            />
            <path
              fill="currentColor"
              d="M20 9.84H4C3.45 9.84 3 10.29 3 10.84V17C3 20 4.5 22 8 22H16C19.5 22 21 20 21 17V10.84C21 10.29 20.55 9.84 20 9.84ZM9.21 18.21C9.11 18.3 9 18.37 8.88 18.42C8.76 18.47 8.63 18.5 8.5 18.5C8.37 18.5 8.24 18.47 8.12 18.42C8 18.37 7.89 18.3 7.79 18.21C7.61 18.02 7.5 17.76 7.5 17.5C7.5 17.24 7.61 16.98 7.79 16.79C7.89 16.7 8 16.63 8.12 16.58C8.36 16.48 8.64 16.48 8.88 16.58C9 16.63 9.11 16.7 9.21 16.79C9.39 16.98 9.5 17.24 9.5 17.5C9.5 17.76 9.39 18.02 9.21 18.21ZM9.42 14.38C9.37 14.5 9.3 14.61 9.21 14.71C9.11 14.8 9 14.87 8.88 14.92C8.76 14.97 8.63 15 8.5 15C8.37 15 8.24 14.97 8.12 14.92C8 14.87 7.89 14.8 7.79 14.71C7.7 14.61 7.63 14.5 7.58 14.38C7.53 14.26 7.5 14.13 7.5 14C7.5 13.87 7.53 13.74 7.58 13.62C7.63 13.5 7.7 13.39 7.79 13.29C7.89 13.2 8 13.13 8.12 13.08C8.36 12.98 8.64 12.98 8.88 13.08C9 13.13 9.11 13.2 9.21 13.29C9.3 13.39 9.37 13.5 9.42 13.62C9.47 13.74 9.5 13.87 9.5 14C9.5 14.13 9.47 14.26 9.42 14.38ZM12.71 14.71C12.61 14.8 12.5 14.87 12.38 14.92C12.26 14.97 12.13 15 12 15C11.87 15 11.74 14.97 11.62 14.92C11.5 14.87 11.39 14.8 11.29 14.71C11.11 14.52 11 14.26 11 14C11 13.74 11.11 13.48 11.29 13.29C11.39 13.2 11.5 13.13 11.62 13.08C11.86 12.97 12.14 12.97 12.38 13.08C12.5 13.13 12.61 13.2 12.71 13.29C12.89 13.48 13 13.74 13 14C13 14.26 12.89 14.52 12.71 14.71Z"
            />
          </g>
        </svg>
      </template>
      <template #clear-icon="{ clear }">
        <svg
          version="1.1"
          role="presentation"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="20"
          height="20"
          class="text-neutral-500"
          @click="clear"
        >
          <path
            d="M18.2997 5.71022C17.9097 5.32022 17.2797 5.32022 16.8897 5.71022L11.9997 10.5902L7.10973 5.70021C6.71973 5.31021 6.08973 5.31021 5.69973 5.70021C5.30973 6.09021 5.30973 6.72022 5.69973 7.11022L10.5897 12.0002L5.69973 16.8902C5.30973 17.2802 5.30973 17.9102 5.69973 18.3002C6.08973 18.6902 6.71973 18.6902 7.10973 18.3002L11.9997 13.4102L16.8897 18.3002C17.2797 18.6902 17.9097 18.6902 18.2997 18.3002C18.6897 17.9102 18.6897 17.2802 18.2997 16.8902L13.4097 12.0002L18.2997 7.11022C18.6797 6.73022 18.6797 6.09022 18.2997 5.71022Z"
            fill="currentColor"
          />
        </svg>
      </template>
      <template #clock-icon>
        <svg
          version="1.1"
          role="presentation"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="20"
          height="20"
          class="text-neutral-500"
        >
          <path
            d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.35 15.57C16.21 15.81 15.96 15.94 15.7 15.94C15.57 15.94 15.44 15.91 15.32 15.83L12.22 13.98C11.45 13.52 10.88 12.51 10.88 11.62V7.52C10.88 7.11 11.22 6.77 11.63 6.77C12.04 6.77 12.38 7.11 12.38 7.52V11.62C12.38 11.98 12.68 12.51 12.99 12.69L16.09 14.54C16.45 14.75 16.57 15.21 16.35 15.57Z"
            fill="currentColor"
          />
        </svg>
      </template>
      <template #arrow-left>
        <svg
          version="1.1"
          role="presentation"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="20"
          height="20"
          class="text-neutral-800"
        >
          <path
            d="M15.0034 8.13242L11.1234 12.0124L15.0034 15.8924C15.3934 16.2824 15.3934 16.9124 15.0034 17.3024C14.6134 17.6924 13.9834 17.6924 13.5934 17.3024L9.00344 12.7124C8.61344 12.3224 8.61344 11.6924 9.00344 11.3024L13.5934 6.71242C13.9834 6.32242 14.6134 6.32242 15.0034 6.71242C15.3834 7.10242 15.3934 7.74242 15.0034 8.13242Z"
            fill="currentColor"
          />
        </svg>
      </template>
      <template #arrow-right>
        <svg
          version="1.1"
          role="presentation"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="20"
          height="20"
          class="text-neutral-800"
        >
          <path
            d="M9.00344 15.8775L12.8834 11.9975L9.00344 8.11754C8.61344 7.72754 8.61344 7.09754 9.00344 6.70754C9.39344 6.31754 10.0234 6.31754 10.4134 6.70754L15.0034 11.2975C15.3934 11.6875 15.3934 12.3175 15.0034 12.7075L10.4134 17.2975C10.0234 17.6875 9.39344 17.6875 9.00344 17.2975C8.62344 16.9075 8.61344 16.2675 9.00344 15.8775Z"
            fill="currentColor"
          />
        </svg>
      </template>
      <template #arrow-up>
        <svg
          version="1.1"
          role="presentation"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="20"
          height="20"
          class="text-neutral-300"
        >
          <path
            d="M15.8727 15.0025L11.9927 11.1225L8.11266 15.0025C7.72266 15.3925 7.09266 15.3925 6.70266 15.0025C6.31266 14.6125 6.31266 13.9825 6.70266 13.5925L11.2927 9.00246C11.6827 8.61246 12.3127 8.61246 12.7027 9.00246L17.2927 13.5925C17.6827 13.9825 17.6827 14.6125 17.2927 15.0025C16.9027 15.3825 16.2627 15.3925 15.8727 15.0025Z"
            fill="currentColor"
          />
        </svg>
      </template>
      <template #arrow-down>
        <svg
          version="1.1"
          role="presentation"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="20"
          height="20"
          class="text-neutral-300"
        >
          <path
            d="M8.12266 9.00246L12.0027 12.8825L15.8827 9.00246C16.2727 8.61246 16.9027 8.61246 17.2927 9.00246C17.6827 9.39246 17.6827 10.0225 17.2927 10.4125L12.7027 15.0025C12.3127 15.3925 11.6827 15.3925 11.2927 15.0025L6.70266 10.4125C6.31266 10.0225 6.31266 9.39246 6.70266 9.00246C7.09266 8.62246 7.73266 8.61246 8.12266 9.00246Z"
            fill="currentColor"
          />
        </svg>
      </template>
      <template #action-select>
        <WebButton
          v-if="!hideCancel"
          variant="outline"
          size="sm"
          @click="closeMenu"
        >
          {{ $t('generate.common.cancel') }}
        </WebButton>
        <WebButton
          size="sm"
          class="tw-web-datepicker__select-button"
          @click="selectDate"
        >
          {{ $t('generate.common.select') }}
        </WebButton>
      </template>
      <template v-for="(_, slotName) in $slots" #[slotName]="slotProps">
        <slot :name="slotName" v-bind="slotProps" />
      </template>
    </VueDatepicker>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, nextTick } from 'vue';
import VueDatepicker from '@vuepic/vue-datepicker';
import type { VueDatePicker } from '@vuepic/vue-datepicker';
import { useCustomPresetRanges, getFormattedDate, formatDate } from './utils';
import type { PageOptions } from '../../types/model';
import WebButton from '@shared/components/button/index.vue';

interface DatePickerProp {
  modelValue: VueDatePicker['modelValue'];
  label?: string | null;
  labelStyle: Record<string, any>;
  format?: VueDatePicker['format'];
  formatted?: string;
  customModelFormat?: string;
  range?: boolean;
  enableCustomRanges?: boolean;
  hideInputIcon?: boolean;
  enableTimePicker?: boolean;
  timePicker?: boolean;
  locale?: string;
  pageOptions: PageOptions
  hideCancel?: boolean;
}

const props = withDefaults(defineProps<DatePickerProp>(), {
  modelValue: null,
  label: null,
  labelStyle: undefined,
  format: undefined,
  formatted: undefined,
  customModelFormat: undefined,
  range: false,
  enableCustomRanges: false,
  hideInputIcon: false,
  enableTimePicker: false,
  timePicker: false,
  locale: 'en',
  hideCancel: false
});

const emit = defineEmits(['update:modelValue', 'update:formatted']);

const compRef = ref<HTMLElement>();
const datepicker = ref();
const internalSelectedDate = ref();

const { customPresetRanges } = useCustomPresetRanges();

const datepickerLocale = computed(() => {
  return props.locale?.split('_')[0] || 'en'
})
const customRanges = computed(() => {
  if (props.range && props.enableCustomRanges) return customPresetRanges.value;
  return [];
});

const inputClassName = computed(() => `tw-web-datepicker__input ${props.hideInputIcon ? 'pl-16' : '!pl-[44px]'}`);
const menuClassName = computed(
  () => `tw-web-datepicker__menu ${props.timePicker && 'time-picker-active'} ${props.range && 'range-active'}`
);
const enableTimePickerValue = computed(() => props.timePicker || props.enableTimePicker);

const datePickerFormat = computed(() => {
  if (props.format) return props.format;
  return getFormattedDate;
});

const dateValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
    if (props.customModelFormat) {
      emit('update:formatted', formatDate(value as Date, props.customModelFormat as string));
    }
  }
});

const inputStyle = computed(() => {
  return {
    focus: {
      borderColor: props.pageOptions?.colors?.theme?.[0]
    },
    selected: {
      textColor: props.pageOptions?.colors?.text?.[2]
    }
  }
})

function handleInternalModelChange(date: Date | Date[]) {
  internalSelectedDate.value = date;
}

const selectDate = () => {
  if (props.range && !internalSelectedDate.value[1]) {
    const [firstDate] = internalSelectedDate.value;
    datepicker.value.updateInternalModelValue([firstDate, firstDate]);
  }
  datepicker.value.selectDate();
};

const closeMenu = () => {
  datepicker.value.closeMenu();
};

function onClosed() {
  nextTick(() => {
    // If select a date and click input to select any date on picker again, second selection cannot apply.
    // To fix this issue, trigger input blur.
    compRef.value?.querySelector('input')?.blur();
  })
}
</script>

<style lang="postcss">
@import '@vuepic/vue-datepicker/dist/main.css';

.tw-web-datepicker {
  @apply font-inter;
  --input-focusBorderColor: v-bind('inputStyle.focus.borderColor');
  --input-selectedTextColor: v-bind('inputStyle.selected.textColor');

  &__input {
    @apply h-40 rounded-8 border border-solid border-neutral-200 py-8 pr-16 text-14 text-neutral-900 transition-shadow placeholder:font-normal placeholder:text-neutral-500 hover:border-neutral-200;
    direction: ltr;

    &:focus, &.dp__input_focus {
      @apply shadow-none ring-0;
      border-color: var(--input-focusBorderColor, '#56C4D6');
    }
  }
  &__select-button {
    background-color: var(--input-focusBorderColor, '#56C4D6') !important;
    color: var(--input-selectedTextColor, '#fff') !important;
  }
  &__menu {
    --dp-primary-color: var(--input-focusBorderColor); /* primary-500 */
    --dp-highlight-color: #edeffc; /* primary-100 */
    --dp-primary-text-color: var(--input-selectedTextColor, '#fff');
    @apply !border-none p-32 font-inter shadow-md;
    .dp__instance_calendar {
      .dp__flex_display {
        @apply gap-16;
      }
    }
    .dp__arrow_top,
    .dp__arrow_bottom {
      @apply !hidden;
    }
    .dp__overlay {
      @apply font-inter;
    }
    .dp__action_row {
      @apply px-0 pb-0 pt-12;
      .dp__selection_preview {
        @apply hidden;
      }
      .dp__action_buttons {
        @apply flex flex-1 justify-end gap-8;
      }
    }
    .dp__month_year {
      &_wrap {
        @apply justify-center gap-4;
      }
      &_select {
        @apply w-auto text-14 font-bold;
      }
    }
    .dp__preset_ranges {
      @apply mr-16 min-w-[130px] py-0 pl-0 pr-16 font-inter font-normal;
      .dp__preset_range {
        @apply rounded-8 p-12 text-14 text-neutral-800 hover:bg-primary-100 hover:font-bold hover:text-primary-500;
      }
    }
    &.time-picker-active {
      @apply p-8 !pb-16;
      .dp__instance_calendar {
        @apply h-96;
      }
      .dp__time_display,
      .dp__time_col,
      .dp__overlay_cell {
        @apply text-14 font-medium leading-5;
      }
      &.range-active {
        .dp__instance_calendar {
          @apply h-[192px];
        }
      }
      .dp__time_display {
        @apply my-4;
      }
      .dp__time_col_reg {
        @apply px-[28px];
      }
      .dp__overlay_cell_pad {
        @apply !py-4;
      }
      .dp__calendar > div{
        @apply !h-auto;
      }
    }
  }
  &__calendar {
    &-cell {
      @apply m-4 h-[30px] w-[30px] !rounded-full font-inter text-14 text-neutral-900;
      &.dp__active_date,
      &.dp__range_start,
      &.dp__range_end {
        color: var(--input-selectedTextColor, '#fff')
      }
      &.dp__range_between,
      &.dp__today {
        @apply text-neutral-700;
      }
      &.dp__range_between {
        @apply bg-primary-100;
      }
      &.dp__cell_offset {
        @apply text-neutral-400;
      }
      &.dp__cell_disabled {
        @apply text-neutral-200;
      }
    }
    .dp__calendar_header_item {
      @apply m-4 h-[30px] w-[30px] font-inter text-14 font-bold;
    }
    .dp__calendar_header_separator {
      @apply hidden;
    }
  }

  .dp__input_icon {
    @apply left-16;
  }
  .dp__clear_icon {
    @apply right-16;
  }
}
</style>
