import { ref } from 'vue';
import {
  endOfMonth,
  startOfMonth,
  subDays,
  format,
  getYear
} from 'date-fns';
import { useI18n } from 'vue-i18n';

export const useCustomPresetRanges = () => {
  const { t } = useI18n();
  const customPresetRanges = ref([
    { label: t('common.date.today'), range: [new Date(), new Date()] },
    { label: t('common.date.yesterday'), range: [subDays(new Date(), 1), subDays(new Date(), 1)] },
    { label: t('common.date.lastDays', [7]), range: [subDays(new Date(), 6), new Date()] },
    { label: t('common.date.lastDays', [30]), range: [subDays(new Date(), 29), new Date()] },
    { label: t('common.date.thisMonth'), range: [startOfMonth(new Date()), endOfMonth(new Date())] },
    { label: t('common.date.lastYear'), range: [subDays(new Date(), 365), new Date()] },
  ]);

  return { customPresetRanges };
};

export const getFormattedDate = (date: Date | Date[]): string => {
  const longFormat = 'd MMM yyyy';
  const shortFormat = 'd MMM';
  if (Array.isArray(date)) {
    let formattedDate = '';
    if (date.length >= 1) {
      formattedDate = format(date[0], `${longFormat} - `);
    }
    if (date.length === 2 && date[1]) {
      const firstDateFormat = getYear(date[0]) === getYear(date[1]) ? shortFormat : longFormat;
      formattedDate = `${format(date[0], firstDateFormat)} - ${format(date[1], longFormat)}`;
    }
    return formattedDate;
  }
  return format(date as Date, longFormat);
};

export const formatDate = (date: Date | Date[], dateFormat: string) => {
  if (Array.isArray(date)) {
    return date.map((dateItem) => {
      if (dateItem) {
        return format(dateItem, dateFormat);
      }
      return null;
    });
  }
  if (date) return format(date, dateFormat);
};
